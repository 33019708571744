import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

interface TAnalyticsStore {
  displayConsent: boolean
  updateDisplayConsent(value: boolean): void
  acceptConsent: boolean | null
  updateConsent(value: boolean): void
}
export const useAnalyticsStore = create<TAnalyticsStore>()(
  devtools(
    persist(
      (set) => ({
        displayConsent: true,
        updateDisplayConsent(value: boolean) {
          set((state) => ({
            ...state,
            displayConsent: value,
          }))
        },
        acceptConsent: null,
        updateConsent(value: boolean) {
          set((state) => ({
            ...state,
            acceptConsent: value,
          }))
        },
      }),
      { name: "analytics-store" }
    )
  )
)
