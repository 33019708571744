import { useConsent } from "@/hook/useConsent"
import { useFacebookPixel } from "@/hook/useFacebookPixel"
import { useSegment } from "@/hook/useSegment"
import { useTagManager } from "@/hook/useTagManager"

export function Analytics() {
  useConsent()
  useFacebookPixel()
  useTagManager()
  useSegment()
}
